import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import 'react-notifications/lib/notifications.css';
import 'react-table/react-table.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";


const override = css`
    display: block;
    margin: 0 auto;
`;


const store = createStore(reducer);


function createNotification (type, value) {
    switch (type) {
        case 'info':
            NotificationManager.info(value,'', 5000);
            break;
        case 'success':
            NotificationManager.success(value, '', 5000);
            break;
        case 'warning':
            NotificationManager.warning(value, '', 5000);
            break;
        case 'error':
            NotificationManager.error(value, '', 5000);
            break;
        default: break;
    }
};

const loader = (value) => {
    return (
        <div style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 9999, cursor:'pointer' }} >
            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <BounceLoader
                    css={override}
                    size={150}
                    color={"rgb(101, 195, 198)"}
                    loading={value}
                />
            </div>
        </div>
    );
}

const app = (
    <Provider store={store}>
        <NotificationContainer/>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();

export { createNotification, loader };
