import React from 'react';

const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn'));
const ForgotPassword = React.lazy(() => import('./Demo/ForgotPassword/ForgotPassword'));

const route = [
    { path: '/login', exact: true, name: 'Signin 1', component: Signin1 },
    { path: '/forgotpassword', exact: true, name: 'Forgot Password', component: ForgotPassword }
];

export default route;